import request from "@/utils/request";
import ENDPOINT from "@/config/endpoint";
const qs = require("qs");

export async function fetchCategory(params) {
  return await request({
    url: ENDPOINT.CATEGORIES,
    method: "get",
    params,
  });
}

export async function fetchCategoryById(id) {
  return await request({
    url: `${ENDPOINT.CATEGORIES}/${id}`,
    method: "get",
  });
}

export async function searchCategory(params) {
  return await request({
    url: ENDPOINT.SEARCH_CATEGORY,
    method: "get",
    params,
  });
}

export async function createCategory(data) {
  return await request({
    url: ENDPOINT.CATEGORIES,
    method: "post",
    data: qs.stringify(data),
  });
}

export async function updateCategory(id, data) {
  return await request({
    url: `${ENDPOINT.CATEGORIES}/${id}`,
    method: "put",
    data: qs.stringify(data),
  });
}

export async function deleteCategory(id) {
  return await request({
    url: `${ENDPOINT.CATEGORIES}/${id}`,
    method: "delete",
  });
}
