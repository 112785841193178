<template>
  <div class="manage-category">
    <el-row type="flex" align="middle" justify="space-between" class="mb-l">
      <el-row type="flex" align="middle" class="page-title">
        <i class="el-icon-menu mr-s" />
        <h3>Quản lý danh mục bài viết</h3>
      </el-row>
      <el-button
        type="primary"
        @click="$router.push({ name: 'CreateCategory' })"
      >
        Thêm
      </el-button>
    </el-row>
    <el-input
      v-model="name"
      @input="handleSearchCategory"
      placeholder="Tìm kiếm danh mục"
      class="search mb-xl"
    />
    <el-table :data="categories" border v-loading="callingAPI">
      <el-table-column label="#" type="index" align="center" width="50px" />
      <el-table-column label="Tên">
        <template slot-scope="scope"> {{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="Mô tả">
        <template slot-scope="scope"> {{ scope.row.description }} </template>
      </el-table-column>
      <el-table-column label="Số lượng bài viết">
        <template slot-scope="scope"> {{ scope.row.posts_count }} </template>
      </el-table-column>
      <el-table-column label="Ngày tạo" width="120px">
        <template slot-scope="scope">
          {{ scope.row.created_at | convertDate }}
        </template>
      </el-table-column>
      <el-table-column label="Sửa" align="center" width="70px">
        <template slot-scope="scope">
          <el-button
            plain
            size="small"
            @click="
              $router.push({
                name: 'UpdateCategory',
                params: { id: scope.row.id },
              })
            "
            icon="el-icon-edit"
            circle
          />
        </template>
      </el-table-column>
      <el-table-column label="Xoá" align="center" width="70px">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="small"
            @click="handeRemoveCategory(scope.row)"
            icon="el-icon-delete"
            circle
          />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" class="mt-m">
      <el-pagination
        background
        layout="prev, pager, next"
        :disabled="callingAPI"
        @current-change="handleChangePage"
        :currentPage="page"
        :pageSize="pageSize"
        :total="total"
      />
    </el-row>
  </div>
</template>

<script>
import { searchCategory, deleteCategory } from "@/services/category";
export default {
  name: "ManageCategory",
  data() {
    return {
      callingAPI: false,
      debounce: null,

      name: "",
      page: 1,
      pageSize: 10,
      total: 0,

      categories: [],
    };
  },
  async beforeMount() {
    await this.loadData();
  },
  methods: {
    async loadData(page = null) {
      try {
        this.callingAPI = true;
        const params = {
          name: this.name,
          page: page ? page : 1,
          limit: this.pageSize,
        };
        const response = await searchCategory(params);
        this.categories = response.data.categories;
        this.total = response.data.paginate.total;
        this.callingAPI = false;
      } catch (error) {
        console.log(error);
        this.callingAPI = false;
      }
    },

    async handleChangePage(page) {
      this.page = page;
      await this.loadData(page);
    },

    async handleSearchCategory() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.loadData();
      }, 500);
    },

    async handeRemoveCategory(row) {
      this.$confirm(`Chắc chắn muốn xoá danh mục ${row.name} ?`, "Warning", {
        confirmButtonText: "Xoá",
        cancelButtonText: "Huỷ",
        type: "warning",
      })
        .then(async () => {
          try {
            this.callingAPI = true;
            const response = await deleteCategory(row.id);
            if (response.message) {
              this.callingAPI = false;
              this.$notify.error({
                title: "Thất bại",
                message: response.message,
                duration: 2000,
              });
              return;
            }
            await this.loadData(this.page);
            this.$notify.success({
              title: "Thành công",
              message: `Xoá danh mục ${row.name} thành công.`,
              duration: 2000,
            });
          } catch (error) {
            this.callingAPI = false;
            this.$notify.error({
              title: "Thất bại",
              message: "Xoá danh mục thất bại.",
              duration: 2000,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
